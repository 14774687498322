var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Default"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAspectDefaut) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("The default ")]), _c('b-link', {
    attrs: {
      "href": "https://en.wikipedia.org/wiki/Aspect_ratio_(image)"
    }
  }, [_vm._v(" aspect ")]), _c('span', [_vm._v(" ratio is ")]), _c('code', [_vm._v("1:1")]), _c('span', [_vm._v(" (ratio of ")]), _c('code', [_vm._v("1")]), _c('span', [_vm._v(" ), which makes the height always be at least the same as the width. The ")]), _c('code', [_vm._v("aspect")]), _c('span', [_vm._v(" prop can be used to specify an arbitrary aspect ratio (i.e. ")]), _c('code', [_vm._v("1.5")]), _c('span', [_vm._v(" ) or a ratio as a string such as ")]), _c('code', [_vm._v("'16:9'")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("'4:3'")]), _c('span', [_vm._v(".")])], 1), _c('b-card-text', [_vm._v(" The width will always be 100% of the available width in the parent element/component. ")]), _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-50 mt-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" Change width of your browser window to see effect ")], 1)]), _c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Aspect ratio",
      "label-for": "ratio",
      "label-cols-md": "auto"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ratio",
      "options": _vm.aspects
    },
    model: {
      value: _vm.aspect,
      callback: function callback($$v) {
        _vm.aspect = $$v;
      },
      expression: "aspect"
    }
  })], 1), _c('b-aspect', {
    attrs: {
      "aspect": _vm.aspect
    }
  }, [_c('p', [_vm._v("This will always be an aspect of \"" + _vm._s(_vm.aspect) + "\", except when the content is too tall.")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }